<template>
  <div>
    <b-form
      v-if="isInitial || isFileLoading"
      novalidate
    >
      <div class="dropbox cursor-pointer d-flex-center">
        <input
          type="file"
          accept=".csv, .xlsx"
          class="input-file"
          :disabled="isFileLoading"
          @change="$emit('files-change',($event.target.files))"
        >
        <div class="d-flex-center flex-column">
          <IAmIcon
            icon="cloudUploadOutline"
            size="40"
            class="text-primary"
          />
          <div
            v-if="isInitial"
            class="d-flex-center flex-column"
          >
            <b-button
              class="my-50"
              variant="primary"
            >
              <h6 class="mb-0 text-light">
                {{ $t('btnAddFileText') }}
              </h6>
            </b-button>
            <h6 class="mb-0 text-muted">
              {{ $t('dropFileText') }}
            </h6>
          </div>
          <div v-if="isFileLoading">
            <h6 class="mb-0 text-muted">
              {{ $t('fileLoading_1') }} {{ fileName }} {{ $t('fileLoading_2') }}
            </h6>
          </div>
        </div>
      </div>
    </b-form>

    <!-- SECTION: ACTION -->
    <div>
      <div class="w-full m-50 d-flex justify-content-between">
        <div>
          <b-button
            variant="flat-danger"
            class="px-1 py-50 text-16px"
            @click.prevent="$emit('hide-modal')"
          >
            {{ $t('cancel') }}
          </b-button>
        </div>
        <div class="d-flex-center gap-1">
          <IAmIcon
            icon="cloudDownloadOutline"
            size="24"
            class="text-info"
          />
          <p
            v-if="canAccess('employee.exportTemplate')"
            class="my-0"
          >
            {{ $t('downloadSampleText_1') }}
            <em
              class="text-primary cursor-pointer"
              @click.prevent="$emit('download-sample-file-handle')"
            >
              {{ $t('downloadSampleText_2') }}
            </em>
            {{ $t('downloadSampleText_3') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { BForm, BButton } from 'bootstrap-vue'

export default {
  components: {
    BForm, BButton,
  },
  props: {
    isInitial: {
      type: Boolean,
      default: false,
    },
    isFileLoading: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: transparent;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  border-radius: 4px;

  &:hover {
  background: #f6f6f6; /* when mouse over to the drop zone, change color */
  // opacity: 50%;
  }
}

.input-file {
  opacity: 0%;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
</style>
