<template lang="">
  <BCard body-class="p-1">
    <div class="d-flex-center justify-content-start gap-1 bg-primary-gradient">
      <IAmIcon
        icon="receipt"
        size="25"
        class="p-1 icon-card"
      />
      <span class="font-medium-3 m-0 fw-700">
        {{ $t('Invoice Ticket List') }}
      </span>
    </div>

    <!-- ANCHOR Filters -->
    <InvoiceTicketsFilter
      :sort-by.sync="sortBy"
      :is-sort-dir-desc.sync="isSortDirDesc"
      :flightDateFrom.sync="flightDateFrom"
      :flightDateTo.sync="flightDateTo"
      :bookingCode.sync="bookingCode"
      :ticketNumber.sync="ticketNumber"
      :customerName.sync="customerName"
      :invoiceTicketType.sync="invoiceTicketType"
      :isInvoicedFilter.sync="isInvoicedFilter"
      @refetch-data="refetchData"
      @refetch-data-without-filters="
        clearFilter()
        refetchData()
      "
    />

    <!-- ANCHOR TOP BUTTON -->
    <div class="w-100 d-flex-center justify-content-end gap-2 mb-50">
      <BButton
        variant="info"
        size="sm"
        class="p-50"
        :to="{ name: 'apps-invoiceTicketCreate' }"
      >
        <span class="text-nowrap d-flex-center gap-1">
          <IAmIcon
            icon="plusOutline"
            size="16"
            color="white"
          />
          {{ $t('invoice.invoiceTickets.create.title') }}
        </span>
      </BButton>

      <BButton
        variant="info"
        size="sm"
        class="p-50"
        @click="onImportHandle"
      >
        <span class="text-nowrap d-flex-center gap-1">
          <IAmIcon
            icon="cloudUploadOutline"
            size="16"
            color="white"
          />
          {{ $t('invoice.invoiceTickets.btn.import') }}
        </span>
      </BButton>

      <BButton
        variant="warning"
        size="sm"
        :disabled="!companyId"
        class="p-50"
        @click="onCreateInvoiceWithTicketHandle(checkedItems)"
      >
        <span class="text-nowrap d-flex-center gap-1">
          <IAmIcon
            icon="increase"
            size="16"
            color="white"
          />
          {{ $t('invoice.create.title') }}
        </span>
      </BButton>
    </div>

    <!-- ANCHOR Table List -->
    <b-table
      ref="refInvoiceTicketListTable"
      style="max-height: 70vh"
      :sticky-header="true"
      class="position-relative"
      :items="getInvoiceTickets"
      responsive
      primary-key="id"
      show-empty
      :empty-text="$t('noMatchingResult')"
      no-border-collapse
      small
      bordered
      :busy="loading"
      :sort-desc.sync="isSortDirDesc"
      :sort-by.sync="sortBy"
      :fields="tableColumsInvoiceTicketList"
    >
      <template
        v-for="column in tableColumsInvoiceTicketList"
        #[`head(${column.key})`]="data"
      >
        <span
          :key="column.key"
          class="text-nowrap"
        >
          <div v-if="column.key === 'checkbox'">
            <b-form-checkbox
              name="id-all-check-box"
              :checked="isAll"
              @change="selectAll"
            />
          </div>
          <div v-else>{{ $t(`invoice.invoiceTickets.fields.${data.column}`) }}</div>
        </span>
      </template>

      <template #table-busy>
        <div class="d-flex-center text-dark my-2 gap-2">
          <b-spinner class="align-middle" />
          <strong>{{ $t('loading') }}</strong>
        </div>
      </template>

      <template #cell(checkbox)="row">
        <b-form-checkbox
          name="item-check-box"
          :checked="isChecked(row.item.id)"
          @change="chooseItem(row.item)"
        />
      </template>

      <template #cell(customerName)="{ item }">
        <div
          v-if="item.customerName"
          class="text-nowrap"
        >
          {{ item.customerName }}
        </div>
        <span
          v-else
          class="text-muted font-italic"
        >
          {{ `<${$t('empty')}>` }}
        </span>
      </template>

      <template #cell(ticketNumber)="{ item }">
        <div
          v-if="item.ticketNumber"
          class="text-nowrap"
        >
          {{ item.ticketNumber }}
        </div>
        <span
          v-else
          class="text-muted font-italic"
        >
          {{ `<${$t('empty')}>` }}
        </span>
      </template>
      <template #cell(bookingCode)="{ item }">
        <div
          v-if="item.bookingCode"
          class="text-nowrap"
        >
          {{ item.bookingCode }}
        </div>
        <span
          v-else
          class="text-muted font-italic"
        >
          {{ `<${$t('empty')}>` }}
        </span>
      </template>
      <template #cell(flightTrip)="{ item }">
        <div
          v-if="item.flightTrip"
          class="text-nowrap"
        >
          {{ item.flightTrip }}
        </div>
        <span
          v-else
          class="text-muted font-italic"
        >
          {{ `<${$t('empty')}>` }}
        </span>
      </template>

      <template #cell(type)="{ item }">
        <BBadge
          :variant="resolveColorOfInvoiceTicketType(item.type)"
          pill
          class="px-75 text-nowrap"
        >
          {{ $t(`invoice.invoiceTickets.type.${item.type}`) }}
        </BBadge>
      </template>

      <template #cell(isInvoiced)="{ item }">
        <BBadge
          :variant="resolveIsInvoicedColor(item.isInvoiced, 'value') || 'secondary'"
          pill
          class="px-75 text-nowrap"
        >
          {{ $t(`invoice.invoiceTickets.isInvoicedFilter.${resolveIsInvoiced(item.isInvoiced, 'value').label}`) }}
        </BBadge>
      </template>

      <template #cell(flightDate)="{ item }">
        <div
          v-if="item.flightDate"
          class="text-nowrap"
        >
          {{ convertISODateTime(item.flightDate).date }}
        </div>
        <span
          v-else
          class="text-muted font-italic"
        >
          {{ `<${$t('empty')}>` }}
        </span>
      </template>

      <template #cell(createdAt)="{ item }">
        <div class="text-nowrap">
          {{ convertISODateTime(item.createdAt).date }}
        </div>
      </template>

      <template #cell(totalAmount)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.unitPrice) }}
        </div>
      </template>

      <template #cell(collectionFee)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.collectionFee) }}
        </div>
      </template>

      <template #cell(valueAddedTax)="{ item }">
        <div class="text-right text-nowrap">
          {{ $t(`invoice.taxTypes.${item.valueAddedTax}`) }}
        </div>
      </template>
      <template #cell(actions)="{ item }">
        <div class="d-flex-center justify-content-start gap-1">
          <BButton
            v-b-tooltip.window.hover="$t('invoice.invoiceTickets.btn.view')"
            variant="flat-dark"
            class="p-50 rounded-circle"
            :to="{ name: 'apps-invoiceTicketDetail', params: { id: item.id } }"
          >
            <IAmIcon
              icon="showOutline"
              size="18"
              color="black"
            />
          </BButton>

          <BButton
            v-if="item.isInvoiced && item?.invoiceItems?.length"
            v-b-tooltip.window.hover="$t('invoice.invoiceTickets.btn.viewInvoice')"
            variant="flat-dark"
            class="p-50 rounded-circle"
            @click="onViewInvoiceHandle(item)"
          >
            <IAmIcon
              icon="invoice"
              size="18"
              color="black"
            />
          </BButton>

          <BButton
            v-if="!item.isInvoiced"
            v-b-tooltip.window.hover="$t('invoice.invoiceTickets.btn.edit')"
            variant="flat-dark"
            class="p-50 rounded-circle"
            :to="{ name: 'apps-invoiceTicketDetail', params: { id: item.id }, query: { edit: true } }"
          >
            <IAmIcon
              icon="editOutline"
              size="18"
              color="black"
            />
          </BButton>

          <BButton
            v-if="!item.isInvoiced"
            v-b-tooltip.window.hover="$t('invoice.create.title')"
            variant="flat-dark"
            class="p-50 rounded-circle"
            @click="onCreateInvoiceWithTicketHandle([item])"
          >
            <IAmIcon
              icon="newFileOutline"
              size="18"
              color="black"
            />
          </BButton>

          <BButton
            v-if="!item.isInvoiced"
            v-b-tooltip.window.hover="$t('invoice.invoiceTickets.btn.delete')"
            variant="flat-dark"
            class="p-50 rounded-circle"
            @click="onDeleteHandle(item.id)"
          >
            <IAmIcon
              icon="trash"
              size="18"
              color="black"
            />
          </BButton>
        </div>
      </template>
    </b-table>

    <!-- ANCHOR: Table Footer -->
    <b-row>
      <b-col
        cols="6"
        md="4"
        class="d-flex align-items-center"
        order="1"
      >
        <v-select
          v-model="sizePerPage"
          :options="sizePerPageOptions"
          :clearable="false"
          class="d-inline-block mx-50"
          style="width: 150px"
          append-to-body
          :calculate-position="withPopper"
        >
          <template #option="data">
            <span>
              {{ data.label }} / {{ $t('page') }}
            </span>
          </template>

          <template #selected-option="data">
            <span>
              {{ data.label }} / {{ $t('page') }}
            </span>
          </template>

          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </b-col>

      <b-col
        cols="12"
        md="4"
        class="d-flex-center"
        order="3"
        order-md="2"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalInvoiceTickets"
          :per-page="sizePerPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>

      <b-col
        cols="6"
        md="4"
        order="2"
        order-md="3"
        class="d-flex-center justify-content-end"
      >
        <span class="text-muted">
          {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
          {{ dataMeta.of }} {{ $t('paginationText.items') }}
        </span>
      </b-col>
    </b-row>
    <InvoiceTicketsImportModal @fetch-data="refetchData" />
  </BCard>
</template>
<script>
import {
  BCard, BTable, BSpinner, BButton, BFormCheckbox, BBadge, BPagination, BCol, BRow,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import { createPopper } from '@popperjs/core'
import uniqBy from 'lodash/uniqBy'
import maxBy from 'lodash/maxBy'

import store from '@/store'
import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
import { useRouter } from '@/@core/utils/utils'
import { sizePerPageOptions } from '@/constants/selectOptions'
import invoiceStoreModule from '@/views/invoices/invoiceStoreModule'
import { apiInvoice } from '@/api'

import useToast from '@useToast'

import useInvoiceTicketHandle, {
  resolveColorOfInvoiceTicketType,
  resolveIsInvoiced,
  resolveIsInvoicedColor,
} from './useInvoiceTicketHandle'
import InvoiceTicketsImportModal from './InvoiceTicketsImportModal.vue'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BButton,
    BFormCheckbox,
    BBadge,
    BPagination,
    BCol,
    BRow,
    InvoiceTicketsFilter: () => import('./InvoiceTicketsFilter.vue'),
    vSelect: () => import('vue-select'),
    InvoiceTicketsImportModal,
  },
  setup() {
    const { router } = useRouter()
    const { toastError, toastSuccess } = useToast()

    const {
      refInvoiceTicketListTable,
      getInvoiceTickets,
      loading,
      refetchData,
      clearFilter,
      dataMeta,
      sizePerPage,
      currentPage,
      totalInvoiceTickets,
      isSortDirDesc,
      sortBy,
      flightDateFrom,
      flightDateTo,
      bookingCode,
      ticketNumber,
      customerName,
      invoiceTicketType,
      isInvoicedFilter,
      INVOICE_STORE,
      tableColumsInvoiceTicketList,
    } = useInvoiceTicketHandle()

    // Register module
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)

    // reset invoice tickets selected
    store.dispatch(`${INVOICE_STORE}/setInvoiceTicketsForCreateInvoice`, [])

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_STORE)) store.unregisterModule(INVOICE_STORE)
    // })

    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const companyId = computed(() => agencyData.value?.company?.id ?? null)

    // for checkbox
    const checkedItems = ref([]) // object
    const isAll = computed(() => {
      if (!refInvoiceTicketListTable.value?.localItems?.length) return false
      const invoiceIdsInTable = refInvoiceTicketListTable.value?.localItems ?? []
      return invoiceIdsInTable.every(invItem => checkedItems.value.map(i => i.id).includes(invItem.id))
    })

    function isChecked(id) {
      return checkedItems.value.map(i => i.id).includes(id)
    }

    function setArray(array, item) {
      const index = array
        ? array.findIndex(element => element.id === item.id)
        : -1
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? array.splice(index, 1) : array.push(item)
      return array
    }

    function chooseItem(item) {
      checkedItems.value = setArray(checkedItems.value, item)
    }

    function selectAll(val) {
      const invoiceIdsInTable = refInvoiceTicketListTable.value?.localItems ?? []
      if (val) {
        checkedItems.value = uniqBy(checkedItems.value.concat(invoiceIdsInTable), 'id')
      } else {
        checkedItems.value = checkedItems.value.filter(
          i => !invoiceIdsInTable.map(i => i.id).includes(i.id),
        )
      }
    }

    function onDeleteHandle(id) {
      const titleVNode = this.$createElement('div', { domProps: { innerHTML: '<span class="font-weight-bolder">XÁC NHẬN XOÁ</span>' } })
      const titleMsg = this.$createElement('div', { domProps: { innerHTML: 'Bạn chắc chắn muốn xoá vé ngoài hệ thống này?' } })
      this.$bvModal
        .msgBoxConfirm([titleMsg], {
          title: [titleVNode],
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Xác nhận',
          cancelTitle: 'Huỷ',
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            await apiInvoice.deleteInvoiceTicket(id)
            toastSuccess({ title: 'messagesList.success' })
            refetchData()
          }
        })
        .catch(() => {
          toastError({ title: 'messagesList.error' })
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    function onImportHandle() {
      this.$bvModal.show('invoice-ticket-import-modal')
    }

    function onCreateInvoiceWithTicketHandle(tickets) {
      if (tickets?.length) {
        store.dispatch(`${INVOICE_STORE}/setInvoiceTicketsForCreateInvoice`, tickets)
      }
      router.push({ name: 'apps-invoiceCreate' })
    }

    function onViewInvoiceHandle(ticket) {
      if (!ticket) return
      const invoiceId = maxBy(ticket.invoiceItems, 'id')?.invoice?.id
      const url = router.resolve({ name: 'apps-invoiceDetail', params: { id: invoiceId } })
      window.open(url.href, '_blank')
    }

    return {
      refetchData,
      getInvoiceTickets,
      refInvoiceTicketListTable,
      tableColumsInvoiceTicketList,
      sortBy,
      isSortDirDesc,
      flightDateFrom,
      flightDateTo,
      bookingCode,
      ticketNumber,
      customerName,
      invoiceTicketType,
      isInvoicedFilter,
      clearFilter,
      formatCurrency,
      convertISODateTime,
      totalInvoiceTickets,
      dataMeta,
      sizePerPage,
      currentPage,
      sizePerPageOptions,
      companyId,
      isAll,
      checkedItems,
      isChecked,
      chooseItem,
      selectAll,
      loading,
      resolveColorOfInvoiceTicketType,
      onDeleteHandle,
      onImportHandle,
      resolveIsInvoicedColor,
      resolveIsInvoiced,
      onCreateInvoiceWithTicketHandle,
      onViewInvoiceHandle,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.maxWidth = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/variables/_variables.scss';
</style>
