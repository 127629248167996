<template>
  <b-modal
    id="invoice-ticket-import-modal"
    :size="sizeModal"
    shadow
    backdrop
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    header-bg-variant="light-info"
    header-class="px-2 py-50"
    @show="onShowHandle"
  >
    <!-- SECTION: Header -->
    <template #modal-header="{ hide }">
      <div class="w-100 d-flex-between">
        <h3 class="mb-0">
          {{ $t('importHeader') }}
        </h3>
        <b-button
          variant="flat-dark"
          class="p-50 rounded"
          @click="hide"
        >
          <feather-icon
            icon="XIcon"
            size="24"
          />
        </b-button>
      </div>
    </template>

    <!-- SECTION: BODY -->
    <form-wizard
      ref="refFormWizard"
      hide-buttons
      class="import-form-wizard steps-transparent"
    >
      <template #title>
        <div class="my-50">
          <span>{{ $t('importBodyText_1') }} {{ $t('invoice.invoiceTickets.shortTitle') }} {{ $t('importBodyText_2') }}</span>
        </div>
      </template>

      <tab-content
        :title="$t('formTitle_1')"
        icon="feather icon-upload-cloud"
      >
        <InvoiceTicketImportUploadFile
          :is-initial="isInitial"
          :is-file-loading="isFileLoading"
          @files-change="filesChange"
          @next-step="formWizardNextStep"
          @hide-modal="hideModal"
          @download-sample-file-handle="downloadSampleFileHandle"
        />
      </tab-content>
      <tab-content
        :title="$t('formTitle_2')"
        icon="feather icon-check"
      >
        <InvoiceTicketImportValidate
          :data-import="dataImport"
          :is-file-success="isFileSuccess"
          :is-importing="isImporting"
          :is-import-failed="isImportFailed"
          :file-name="fileName"
          @reset="reset"
          @next-step="formWizardNextStep"
          @prev-step="formWizardPrevStep"
          @hide-modal="hideModal"
          @import-file="importFile"
        />
      </tab-content>
      <tab-content
        :title="$t('formTitle_3')"
        icon="feather icon-user-check"
      >
        <InvoiceTicketImportDone
          :is-file-failed="isFileFailed"
          :is-import-success="isImportSuccess"
          :is-import-failed="isImportFailed"
          :import-response="importResponse"
          :import-error="importError"
          :file-name="fileName"
          @reset="reset"
          @next-step="formWizardNextStep"
          @prev-step="formWizardPrevStep"
          @hide-modal="hideModal"
        />
      </tab-content>
    </form-wizard>
  </b-modal>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import { FormWizard, TabContent } from 'vue-form-wizard'

import { INVOICE_TICKET_COLUMNS_IMPORT } from '@/constants/import'
import { apiInvoice } from '@/api'
import { convertISODateTime } from '@/@core/utils/filter'

import { useInputCsvToArray, useExcelToArray } from '@core/comp-functions/forms/form-utils'

import useEmployeeHandle from '@employee/useEmployeeHandle.js'

import InvoiceTicketImportUploadFile from './components/InvoiceTicketImportUploadFile.vue'
import InvoiceTicketImportValidate from './components/InvoiceTicketImportValidate.vue'
import InvoiceTicketImportDone from './components/InvoiceTicketImportDone.vue'

export default {
  components: {
    BModal,
    BButton,
    FormWizard,
    TabContent,
    InvoiceTicketImportUploadFile,
    InvoiceTicketImportValidate,
    InvoiceTicketImportDone,
  },
  props: {
  },
  setup(_, { emit }) {
    // SECTION Form wizard
    const refFormWizard = ref()
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    const formWizardPrevStep = () => {
      refFormWizard.value.prevTab()
    }

    const formWizardBackToFirstStep = () => {
      // eslint-disable-next-line no-unused-expressions
      refFormWizard.value?.navigateToTab(0)
    }

    const formWizardGoToLastStep = () => {
      refFormWizard.value.changeTab(refFormWizard.value.activeTabIndex, refFormWizard.value.tabs.length - 1)
      // refFormWizard.value.navigateToTab(refFormWizard.value.tabs.length - 1)
    }

    // SECTION handle import
    const STATUS_INITIAL = 'STATUS_INITIAL'
    const STATUS_FILE_LOADING = 'STATUS_FILE_LOADING'
    const STATUS_FILE_SUCCESS = 'STATUS_FILE_SUCCESS'
    const STATUS_FILE_FAILED = 'STATUS_FILE_FAILED'
    const STATUS_IMPORTING = 'STATUS_IMPORTING'
    const STATUS_IMPORT_SUCCESS = 'STATUS_IMPORT_SUCCESS'
    const STATUS_IMPORT_FAILED = 'STATUS_IMPORT_FAILED'

    const currentStatus = ref(STATUS_INITIAL)
    const uploadFile = ref(null)
    const importResponse = ref(null)
    const importError = ref(null)
    const fileName = ref(null)
    const fileNameExtension = ref(null)
    const dataImport = ref([])

    const isInitial = computed(() => currentStatus.value === STATUS_INITIAL)
    const isFileLoading = computed(() => currentStatus.value === STATUS_FILE_LOADING)
    const isFileSuccess = computed(() => currentStatus.value === STATUS_FILE_SUCCESS)
    const isFileFailed = computed(() => currentStatus.value === STATUS_FILE_FAILED)
    const isImporting = computed(() => currentStatus.value === STATUS_IMPORTING)
    const isImportSuccess = computed(() => currentStatus.value === STATUS_IMPORT_SUCCESS)
    const isImportFailed = computed(() => currentStatus.value === STATUS_IMPORT_FAILED)

    const { importEmployees } = useEmployeeHandle()

    function reset() {
      currentStatus.value = STATUS_INITIAL
      importError.value = null
      importResponse.value = null
      uploadFile.value = null
      fileName.value = null
      fileNameExtension.value = null
      dataImport.value = []

      formWizardBackToFirstStep()
    }

    function resolveInvoiceTicketType(type) {
      if (!type) return null
      const types = [
        { value: 'AIRLINE_TICKET', keyWords: ['AIRLINE_TICKET', 'Airline Ticket', 'Vé máy bay'] },
        { value: 'TRAIN_TICKET', keyWords: ['TRAIN_TICKET', 'Train Ticket', 'Vé tàu'] },
        { value: 'TOUR_TICKET', keyWords: ['TOUR_TICKET', 'Tour Ticket', 'Vé tour'] },
        { value: 'OTHER', keyWords: ['OTHER', 'Other', 'Khác'] },
      ]
      const found = types.find(item => item.keyWords.map(i => i.toUpperCase()).includes(type.toUpperCase()))
      return found?.value ?? type
    }

    function resolveVAT(value) {
      const vat = String(value)
      if (!vat) return null
      const vats = [
        { value: 'ZERO_PERCENT', keyWords: ['ZERO_PERCENT', 'Zero percent', '0%', '0'] },
        { value: 'FIVE_PERCENT', keyWords: ['FIVE_PERCENT', 'Five percent', '5%', '0.05'] },
        { value: 'EIGHT_PERCENT', keyWords: ['EIGHT_PERCENT', 'Eight percent', '8%', '0.08'] },
        { value: 'TEN_PERCENT', keyWords: ['TEN_PERCENT', 'Ten percent', '10%', '0.1'] },
        { value: 'NON_TAXABLE', keyWords: ['NON_TAXABLE', 'Non taxable', 'Không chịu thuế'] },
        { value: 'NON_DECLARED', keyWords: ['NON_DECLARED', 'Non declared', 'Không kê khai nộp thuế'] },
        { value: 'OTHER', keyWords: ['OTHER', 'Other', 'Khác'] },
      ]
      const found = vats.find(item => item.keyWords.map(i => i.toUpperCase()).includes(vat.toUpperCase()))
      return found?.value ?? vat
    }

    async function filesChange(fileList) {
      currentStatus.value = STATUS_FILE_LOADING
      uploadFile.value = fileList[0]
      fileName.value = uploadFile.value.name
      fileNameExtension.value = uploadFile.value.name.split('.').at(-1)

      if (fileNameExtension.value === 'csv') {
        useInputCsvToArray(uploadFile.value, response => {
          if (response.error) {
            currentStatus.value = STATUS_FILE_FAILED
            importError.value = response.error
            formWizardGoToLastStep()
          } else {
            dataImport.value = response
            if (dataImport.value.length) {
              currentStatus.value = STATUS_FILE_SUCCESS
            } else {
              currentStatus.value = STATUS_FILE_FAILED
              importError.value = 'Import file is empty.'
              formWizardGoToLastStep()
              return
            }
            formWizardNextStep()
          }
        })
      } else if (fileNameExtension.value === 'xlsx') { // ['xlsx'].includes(fileNameExtension.value)
        try {
          dataImport.value = await useExcelToArray(uploadFile.value, INVOICE_TICKET_COLUMNS_IMPORT)
          if (dataImport.value.length) {
            dataImport.value = dataImport.value.map(item => ({
              ...item,
              type: resolveInvoiceTicketType(item.type),
              valueAddedTax: resolveVAT(item.valueAddedTax),
              flightDate: item.flightDate ? convertISODateTime(item.flightDate).dateFilter : null,
            }))
            currentStatus.value = STATUS_FILE_SUCCESS
          } else {
            currentStatus.value = STATUS_FILE_FAILED
            importError.value = 'Import file is empty.'
            formWizardGoToLastStep()
            return
          }
          formWizardNextStep()
        } catch (error) {
          console.error({ error })
          currentStatus.value = STATUS_FILE_FAILED
          importError.value = error.message
          formWizardGoToLastStep()
        }
      }
    }

    async function importFile(dataValidated, refForm) {
      const isValid = await refForm.validate()
      if (!isValid) return
      currentStatus.value = STATUS_IMPORTING
      const dataToRequest = {
        tickets: dataValidated.map(item => {
          let data = {}
          try {
            data = {
              bookingCode: item.bookingCode || undefined,
              ticketNumber: item.ticketNumber,
              customerName: item.customerName || undefined,
              flightTrip: item.flightTrip || undefined,
              flightDate: item.flightDate || undefined,
              type: item.type,
              unitPrice: item.unitPrice,
              valueAddedTax: item.valueAddedTax,
              collectionFee: item.collectionFee,
            }
          } catch (error) {
            console.error({ error })
            importError.value = 'Import file is wrong format. Please download sample file.'
          }
          return data
        }),
      }
      if (importError.value) {
        currentStatus.value = STATUS_IMPORT_FAILED
      } else {
        try {
          const response = await apiInvoice.bulkImportInvoiceTickets(dataToRequest)
          if (response) {
            importResponse.value = response
            currentStatus.value = STATUS_IMPORT_SUCCESS
            emit('fetch-data')
            formWizardNextStep()
          }
        } catch (error) {
          console.error({ error })
          importError.value = error.message
          currentStatus.value = STATUS_IMPORT_FAILED
        }
      }
    }

    // show download sample file csv
    function downloadSampleFileHandle() {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('invoice.invoiceTickets.import.downloadSampleConfirm') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            await apiInvoice.exportTemplateInvoiceTickets()
          }
        })
    }

    // SECTION handle Modal
    const sizeModal = computed(() => (currentStatus.value === STATUS_FILE_SUCCESS ? 'xl' : 'lg'))

    function hideModal() {
      this.$bvModal.hide('invoice-ticket-import-modal')
    }

    function onShowHandle() {
      reset()
    }
    return {
      hideModal,
      sizeModal,

      isInitial,
      isFileLoading,
      isFileSuccess,
      isFileFailed,
      isImporting,
      isImportSuccess,
      isImportFailed,
      currentStatus,
      importResponse,
      importError,
      uploadFile,
      fileName,
      dataImport,

      filesChange,
      reset,
      importFile,
      importEmployees,
      downloadSampleFileHandle,

      // form wizard
      refFormWizard,
      formWizardNextStep,
      formWizardPrevStep,
      formWizardBackToFirstStep,
      formWizardGoToLastStep,
      onShowHandle,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.vue-form-wizard ::v-deep {
  .wizard-nav-pills {
    justify-content: center;
    width: 100%;
    li {
      padding-bottom: 10px !important;
      pointer-events: none !important; // prevent click to content tab navigation
    }
  }
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
