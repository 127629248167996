var render = function () {
  var _vm$tableData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    staticClass: "w-100"
  }, [_vm.isFileSuccess || _vm.isImporting ? _c('div', {
    staticClass: "box d-flex-center bg-light flex-column py-2 px-3"
  }, [_c('div', {
    staticClass: "d-flex-between w-100 mb-75"
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.$t('importValidateHeader_1')) + " "), _c('em', {
    staticClass: "text-info"
  }, [_vm._v(_vm._s(_vm.fileName))]), _vm._v(" " + _vm._s(_vm.$t('importValidateHeader_2')) + " ")]), _c('div', [_c('b-button', {
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('reset');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('uploadAgain')) + " ")])], 1)]), _c('IAmOverlay', {
    attrs: {
      "loading": _vm.isImporting
    }
  }, [_c('b-table', {
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "60vh"
    },
    attrs: {
      "sticky-header": "",
      "responsive": "",
      "show-empty": "",
      "empty-text": _vm.$t('noRecordFund'),
      "items": _vm.tableData,
      "fields": _vm.tableFields,
      "no-border-collapse": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableFields, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('div', {
            key: column.label,
            staticClass: "d-flex-center gap-1"
          }, [column.label !== 'actions' ? _c('span', [_vm._v(_vm._s(_vm.$t("invoice.invoiceTickets.fields.".concat(column.label))))]) : _vm._e(), column !== null && column !== void 0 && column.required ? _c('small', {
            staticClass: "text-danger fw-700"
          }, [_vm._v("(*)")]) : _vm._e()])];
        },
        proxy: true
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(flightDate)",
      fn: function fn(data) {
        return [_c('validation-provider', {
          attrs: {
            "immediate": "",
            "name": _vm.$t('invoice.invoiceTickets.fields.flightDate'),
            "mode": "eager",
            "rules": "required",
            "vid": "flightDate-".concat(data.index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('flat-pickr', {
                staticClass: "d-none",
                attrs: {
                  "id": "flightDate-".concat(data.index),
                  "config": Object.assign({}, _vm.configFlatPickr)
                },
                model: {
                  value: data.item.flightDate,
                  callback: function callback($$v) {
                    _vm.$set(data.item, "flightDate", $$v);
                  },
                  expression: "data.item.flightDate"
                }
              }), _c('div', {
                staticClass: "form-control p-0",
                class: validationContext.errors[0] ? 'is-invalid' : '',
                attrs: {
                  "id": "flightDate-".concat(data.index, "-show")
                }
              }, [_c('flat-pickr', {
                staticClass: "form-control px-50 py-0",
                attrs: {
                  "name": _vm.$t('invoice.invoiceTickets.fields.flightDate'),
                  "config": Object.assign({}, _vm.configFlatPickr, {
                    altInputClass: 'form-control input px-1 py-0 border-transparent height-25rem'
                  }),
                  "placeholder": _vm.$t('invoice.invoiceTickets.fields.flightDate')
                },
                model: {
                  value: data.item.flightDate,
                  callback: function callback($$v) {
                    _vm.$set(data.item, "flightDate", $$v);
                  },
                  expression: "data.item.flightDate"
                }
              })], 1), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "flightDate-".concat(data.index, "-show"),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(data) {
        return [_c('validation-provider', {
          attrs: {
            "immediate": "",
            "name": _vm.$t('invoice.invoiceTickets.fields.bookingCode'),
            "mode": "eager",
            "rules": "",
            "vid": "bookingCode-".concat(data.index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-input', {
                attrs: {
                  "id": "bookingCode-".concat(data.index),
                  "placeholder": _vm.$t('invoice.invoiceTickets.fields.bookingCode'),
                  "state": validationContext.errors[0] ? false : null,
                  "formatter": _vm.onlyUpperCaseFormatter
                },
                model: {
                  value: data.item.bookingCode,
                  callback: function callback($$v) {
                    _vm.$set(data.item, "bookingCode", $$v);
                  },
                  expression: "data.item.bookingCode"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "bookingCode-".concat(data.index),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(ticketNumber)",
      fn: function fn(data) {
        return [_c('validation-provider', {
          attrs: {
            "immediate": "",
            "name": _vm.$t('invoice.invoiceTickets.fields.ticketNumber'),
            "mode": "eager",
            "rules": "required",
            "vid": "ticketNumber-".concat(data.index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-input', {
                attrs: {
                  "id": "ticketNumber-".concat(data.index),
                  "placeholder": _vm.$t('invoice.invoiceTickets.fields.ticketNumber'),
                  "state": validationContext.errors[0] ? false : null,
                  "formatter": _vm.onlyUpperCaseFormatter
                },
                model: {
                  value: data.item.ticketNumber,
                  callback: function callback($$v) {
                    _vm.$set(data.item, "ticketNumber", $$v);
                  },
                  expression: "data.item.ticketNumber"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "ticketNumber-".concat(data.index),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(customerName)",
      fn: function fn(data) {
        return [_c('validation-provider', {
          attrs: {
            "immediate": "",
            "name": _vm.$t('invoice.invoiceTickets.fields.customerName'),
            "mode": "eager",
            "rules": "",
            "vid": "customerName-".concat(data.index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-input', {
                attrs: {
                  "id": "customerName-".concat(data.index),
                  "placeholder": _vm.$t('invoice.invoiceTickets.fields.customerName'),
                  "state": validationContext.errors[0] ? false : null,
                  "formatter": _vm.onlyUpperCaseFormatter
                },
                model: {
                  value: data.item.customerName,
                  callback: function callback($$v) {
                    _vm.$set(data.item, "customerName", $$v);
                  },
                  expression: "data.item.customerName"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "customerName-".concat(data.index),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(flightTrip)",
      fn: function fn(data) {
        return [_c('validation-provider', {
          attrs: {
            "immediate": "",
            "name": _vm.$t('invoice.invoiceTickets.fields.flightTrip'),
            "mode": "eager",
            "rules": "",
            "vid": "flightTrip-".concat(data.index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-input', {
                attrs: {
                  "id": "flightTrip-".concat(data.index),
                  "placeholder": _vm.$t('invoice.invoiceTickets.fields.flightTrip'),
                  "state": validationContext.errors[0] ? false : null,
                  "formatter": _vm.onlyUpperCaseFormatter
                },
                model: {
                  value: data.item.flightTrip,
                  callback: function callback($$v) {
                    _vm.$set(data.item, "flightTrip", $$v);
                  },
                  expression: "data.item.flightTrip"
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "flightTrip-".concat(data.index),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_c('validation-provider', {
          attrs: {
            "immediate": "",
            "name": _vm.$t('invoice.invoiceTickets.fields.type'),
            "mode": "eager",
            "rules": "required",
            "vid": "type-".concat(data.index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('div', {
                attrs: {
                  "id": "type-".concat(data.index, "-show")
                }
              }, [_c('b-form-input', {
                staticClass: "d-none",
                attrs: {
                  "id": "type-".concat(data.index),
                  "value": data.item.type
                }
              }), _c('v-select', {
                class: "w-100 ".concat(validationContext.errors[0] ? 'is-invalid' : ''),
                staticStyle: {
                  "font-size": "1rem",
                  "width": "150px !important"
                },
                attrs: {
                  "append-to-body": "",
                  "calculate-position": _vm.withPopper,
                  "name": _vm.$t('invoice.invoiceTickets.fields.invoiceTicketType'),
                  "options": _vm.INVOICE_TICKET_TYPE_OPTIONS,
                  "label": "label",
                  "clearable": false,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "placeholder": _vm.$t('placeholderSelect')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(_ref) {
                    var label = _ref.label;
                    return [_c('BBadge', {
                      staticClass: "px-75",
                      attrs: {
                        "variant": _vm.resolveColorOfInvoiceTicketType(label) || 'secondary',
                        "pill": ""
                      }
                    }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.type.".concat(label))) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(_ref2) {
                    var label = _ref2.label;
                    return [_c('BBadge', {
                      staticClass: "px-75",
                      attrs: {
                        "variant": _vm.resolveColorOfInvoiceTicketType(label) || 'secondary',
                        "pill": ""
                      }
                    }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.type.".concat(label))) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: data.item.type,
                  callback: function callback($$v) {
                    _vm.$set(data.item, "type", $$v);
                  },
                  expression: "data.item.type"
                }
              })], 1), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "type-".concat(data.index, "-show"),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(totalAmount)",
      fn: function fn(data) {
        return [_c('validation-provider', {
          attrs: {
            "immediate": "",
            "name": _vm.$t('invoice.invoiceTickets.fields.totalAmount'),
            "mode": "eager",
            "rules": "required",
            "vid": "totalAmount-".concat(data.index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-input', {
                staticClass: "d-none",
                attrs: {
                  "placeholder": _vm.$t('invoice.invoiceTickets.fields.totalAmount'),
                  "state": validationContext.errors[0] ? false : null,
                  "formatter": _vm.onlyUpperCaseFormatter
                },
                model: {
                  value: data.item.unitPrice,
                  callback: function callback($$v) {
                    _vm.$set(data.item, "unitPrice", $$v);
                  },
                  expression: "data.item.unitPrice"
                }
              }), _c('IAmInputMoney', {
                staticClass: "flex-grow-1",
                attrs: {
                  "id": "totalAmount-".concat(data.index),
                  "value-money": data.item.unitPrice,
                  "state": validationContext.errors[0] ? false : null,
                  "name": _vm.$t('invoice.invoiceTickets.fields.totalAmount'),
                  "can-input-negative": true,
                  "placeholder": _vm.$t('invoice.invoiceTickets.fields.totalAmount')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(data.item, "unitPrice", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(data.item, "unitPrice", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "totalAmount-".concat(data.index),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(valueAddedTax)",
      fn: function fn(data) {
        return [_c('validation-provider', {
          attrs: {
            "immediate": "",
            "name": _vm.$t('invoice.invoiceTickets.fields.valueAddedTax'),
            "mode": "eager",
            "rules": "required",
            "vid": "valueAddedTax-".concat(data.index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('div', {
                attrs: {
                  "id": "valueAddedTax-".concat(data.index, "-show")
                }
              }, [_c('b-form-input', {
                staticClass: "d-none",
                attrs: {
                  "id": "valueAddedTax-".concat(data.index),
                  "value": data.item.valueAddedTax
                }
              }), _c('v-select', {
                class: "w-100 ".concat(validationContext.errors[0] ? 'is-invalid' : ''),
                staticStyle: {
                  "font-size": "1rem",
                  "width": "100px !important"
                },
                attrs: {
                  "name": _vm.$t('invoice.invoiceTickets.fields.valueAddedTax'),
                  "options": _vm.TAX_TYPES,
                  "append-to-body": "",
                  "calculate-position": _vm.withPopper,
                  "label": "label",
                  "clearable": false,
                  "reduce": function reduce(val) {
                    return val.label;
                  },
                  "state": validationContext.errors[0] ? false : null,
                  "placeholder": _vm.$t('placeholderSelect')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(_ref3) {
                    var label = _ref3.label;
                    return [_vm._v(" " + _vm._s(_vm.$t("invoice.taxTypes.".concat(label))) + " ")];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(_ref4) {
                    var label = _ref4.label;
                    return [_vm._v(" " + _vm._s(_vm.$t("invoice.taxTypes.".concat(label))) + " ")];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: data.item.valueAddedTax,
                  callback: function callback($$v) {
                    _vm.$set(data.item, "valueAddedTax", $$v);
                  },
                  expression: "data.item.valueAddedTax"
                }
              })], 1), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "valueAddedTax-".concat(data.index, "-show"),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : data.item.valueAddedTax ? _c('b-tooltip', {
                attrs: {
                  "target": "valueAddedTax-".concat(data.index, "-show"),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "dark"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(_vm.$t("invoice.taxTypes.".concat(data.item.valueAddedTax))) + " ")])]) : _vm._e()];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(collectionFee)",
      fn: function fn(data) {
        return [_c('validation-provider', {
          attrs: {
            "immediate": "",
            "name": _vm.$t('invoice.invoiceTickets.fields.collectionFee'),
            "mode": "eager",
            "rules": "required",
            "vid": "collectionFee-".concat(data.index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-input', {
                staticClass: "d-none",
                attrs: {
                  "placeholder": _vm.$t('invoice.invoiceTickets.fields.collectionFee'),
                  "state": validationContext.errors[0] ? false : null,
                  "formatter": _vm.onlyUpperCaseFormatter
                },
                model: {
                  value: data.item.collectionFee,
                  callback: function callback($$v) {
                    _vm.$set(data.item, "collectionFee", $$v);
                  },
                  expression: "data.item.collectionFee"
                }
              }), _c('IAmInputMoney', {
                staticClass: "flex-grow-1",
                attrs: {
                  "id": "collectionFee-".concat(data.index),
                  "value-money": data.item.collectionFee,
                  "state": validationContext.errors[0] ? false : null,
                  "name": _vm.$t('invoice.invoiceTickets.fields.collectionFee'),
                  "can-input-negative": true,
                  "placeholder": _vm.$t('invoice.invoiceTickets.fields.collectionFee')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(data.item, "collectionFee", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(data.item, "collectionFee", $event);
                  }
                }
              }), validationContext.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "target": "collectionFee-".concat(data.index),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "danger"
                }
              }, [_c('h6', {
                staticClass: "text-white py-25 mb-0"
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('BButton', {
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.removeRow(data.index);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "trash",
            "size": "18"
          }
        })], 1)];
      }
    }], null, true)
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "w-full m-50 d-flex-center gap-2"
  }, [_c('b-button', {
    staticClass: "p-1",
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$emit('hide-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]), _c('b-button', {
    staticClass: "p-1",
    attrs: {
      "variant": "primary",
      "disabled": _vm.isImportFailed || !((_vm$tableData = _vm.tableData) !== null && _vm$tableData !== void 0 && _vm$tableData.length)
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$emit('import-file', _vm.tableData, _vm.refFormObserver);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('import')) + " ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }