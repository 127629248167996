var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "invoice-ticket-import-modal",
      "size": _vm.sizeModal,
      "shadow": "",
      "backdrop": "",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "hide-footer": "",
      "header-bg-variant": "light-info",
      "header-class": "px-2 py-50"
    },
    on: {
      "show": _vm.onShowHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "w-100 d-flex-between"
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('importHeader')) + " ")]), _c('b-button', {
          staticClass: "p-50 rounded",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": hide
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon",
            "size": "24"
          }
        })], 1)], 1)];
      }
    }])
  }, [_c('form-wizard', {
    ref: "refFormWizard",
    staticClass: "import-form-wizard steps-transparent",
    attrs: {
      "hide-buttons": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "my-50"
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('importBodyText_1')) + " " + _vm._s(_vm.$t('invoice.invoiceTickets.shortTitle')) + " " + _vm._s(_vm.$t('importBodyText_2')))])])];
      },
      proxy: true
    }])
  }, [_c('tab-content', {
    attrs: {
      "title": _vm.$t('formTitle_1'),
      "icon": "feather icon-upload-cloud"
    }
  }, [_c('InvoiceTicketImportUploadFile', {
    attrs: {
      "is-initial": _vm.isInitial,
      "is-file-loading": _vm.isFileLoading
    },
    on: {
      "files-change": _vm.filesChange,
      "next-step": _vm.formWizardNextStep,
      "hide-modal": _vm.hideModal,
      "download-sample-file-handle": _vm.downloadSampleFileHandle
    }
  })], 1), _c('tab-content', {
    attrs: {
      "title": _vm.$t('formTitle_2'),
      "icon": "feather icon-check"
    }
  }, [_c('InvoiceTicketImportValidate', {
    attrs: {
      "data-import": _vm.dataImport,
      "is-file-success": _vm.isFileSuccess,
      "is-importing": _vm.isImporting,
      "is-import-failed": _vm.isImportFailed,
      "file-name": _vm.fileName
    },
    on: {
      "reset": _vm.reset,
      "next-step": _vm.formWizardNextStep,
      "prev-step": _vm.formWizardPrevStep,
      "hide-modal": _vm.hideModal,
      "import-file": _vm.importFile
    }
  })], 1), _c('tab-content', {
    attrs: {
      "title": _vm.$t('formTitle_3'),
      "icon": "feather icon-user-check"
    }
  }, [_c('InvoiceTicketImportDone', {
    attrs: {
      "is-file-failed": _vm.isFileFailed,
      "is-import-success": _vm.isImportSuccess,
      "is-import-failed": _vm.isImportFailed,
      "import-response": _vm.importResponse,
      "import-error": _vm.importError,
      "file-name": _vm.fileName
    },
    on: {
      "reset": _vm.reset,
      "next-step": _vm.formWizardNextStep,
      "prev-step": _vm.formWizardPrevStep,
      "hide-modal": _vm.hideModal
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }