var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isFileFailed ? _c('div', {
    staticClass: "box bg-light d-flex flex-column justify-content-center align-items-center"
  }, [_c('div', [_c('h4', [_vm._v(" " + _vm._s(_vm.$t('loadFailText_1')) + " "), _c('em', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.fileName))]), _vm._v(" " + _vm._s(_vm.$t('loadFailText_2')) + " ")]), _c('span', [_vm._v(_vm._s(_vm.importError))])]), _c('b-button', {
    staticClass: "mt-75",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('reset');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('tryAgain')) + " ")])], 1) : _vm._e(), _vm.isImportSuccess ? _c('div', {
    staticClass: "box d-flex flex-column justify-content-center align-items-center"
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.$t('importCompleted')) + " ")]), _c('div', {
    staticClass: "w-100 px-3"
  }, [_c('b-row', {
    staticClass: "py-50"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('feather-icon', {
    staticClass: "d-block text-success",
    attrs: {
      "icon": "CheckIcon",
      "size": "32"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.importResponse.tickets ? _vm.importResponse.tickets.length : 0) + " " + _vm._s(_vm.$t('importDoneText')) + ".")])])], 1), _vm.importResponse.errors.length > 0 ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('feather-icon', {
    staticClass: "d-block text-danger",
    attrs: {
      "icon": "XIcon",
      "size": "32"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex flex-column justify-content-center",
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.importResponse.errors ? _vm.importResponse.errors.length : 0) + " " + _vm._s(_vm.$t('importFailText')))]), _c('b-table', {
    staticClass: "mt-50",
    attrs: {
      "items": _vm.importResponse.errors,
      "fields": _vm.tableFields
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableFields, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn() {
          return [_c('div', {
            key: column,
            staticClass: "d-flex-center gap-1"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.fields.".concat(column))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(ticketNumber)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.item.ticketNumber) + " ")];
      }
    }, {
      key: "cell(message)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.findErrorMessageByKey(item.message)) + " ")];
      }
    }], null, true)
  })], 1)], 1) : _vm._e()], 1)]) : _vm._e(), _vm.isImportFailed ? _c('div', {
    staticClass: "box d-flex flex-column justify-content-center align-items-center"
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.$t('importFailed')) + " ")]), _c('span', [_vm._v(_vm._s(_vm.importError))]), _c('b-button', {
    staticClass: "mt-75",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('reset');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('tryAgain')) + " ")])], 1) : _vm._e(), _c('div', [_c('div', {
    staticClass: "w-full m-50 d-flex justify-content-between align-items-center"
  }, [_c('div', [!_vm.isImportSuccess ? _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('hide-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_vm.isImportSuccess ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('hide-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('done')) + " ")]) : _vm._e()], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }