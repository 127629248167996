var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    attrs: {
      "body-class": "p-1"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start gap-1 bg-primary-gradient"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "receipt",
      "size": "25"
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('Invoice Ticket List')) + " ")])], 1), _c('InvoiceTicketsFilter', {
    attrs: {
      "sort-by": _vm.sortBy,
      "is-sort-dir-desc": _vm.isSortDirDesc,
      "flightDateFrom": _vm.flightDateFrom,
      "flightDateTo": _vm.flightDateTo,
      "bookingCode": _vm.bookingCode,
      "ticketNumber": _vm.ticketNumber,
      "customerName": _vm.customerName,
      "invoiceTicketType": _vm.invoiceTicketType,
      "isInvoicedFilter": _vm.isInvoicedFilter
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:isSortDirDesc": function updateIsSortDirDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:is-sort-dir-desc": function updateIsSortDirDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:flightDateFrom": function updateFlightDateFrom($event) {
        _vm.flightDateFrom = $event;
      },
      "update:flight-date-from": function updateFlightDateFrom($event) {
        _vm.flightDateFrom = $event;
      },
      "update:flightDateTo": function updateFlightDateTo($event) {
        _vm.flightDateTo = $event;
      },
      "update:flight-date-to": function updateFlightDateTo($event) {
        _vm.flightDateTo = $event;
      },
      "update:bookingCode": function updateBookingCode($event) {
        _vm.bookingCode = $event;
      },
      "update:booking-code": function updateBookingCode($event) {
        _vm.bookingCode = $event;
      },
      "update:ticketNumber": function updateTicketNumber($event) {
        _vm.ticketNumber = $event;
      },
      "update:ticket-number": function updateTicketNumber($event) {
        _vm.ticketNumber = $event;
      },
      "update:customerName": function updateCustomerName($event) {
        _vm.customerName = $event;
      },
      "update:customer-name": function updateCustomerName($event) {
        _vm.customerName = $event;
      },
      "update:invoiceTicketType": function updateInvoiceTicketType($event) {
        _vm.invoiceTicketType = $event;
      },
      "update:invoice-ticket-type": function updateInvoiceTicketType($event) {
        _vm.invoiceTicketType = $event;
      },
      "update:isInvoicedFilter": function updateIsInvoicedFilter($event) {
        _vm.isInvoicedFilter = $event;
      },
      "update:is-invoiced-filter": function updateIsInvoicedFilter($event) {
        _vm.isInvoicedFilter = $event;
      },
      "refetch-data": _vm.refetchData,
      "refetch-data-without-filters": function refetchDataWithoutFilters($event) {
        _vm.clearFilter();
        _vm.refetchData();
      }
    }
  }), _c('div', {
    staticClass: "w-100 d-flex-center justify-content-end gap-2 mb-50"
  }, [_c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "info",
      "size": "sm",
      "to": {
        name: 'apps-invoiceTicketCreate'
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "plusOutline",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.create.title')) + " ")], 1)]), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": _vm.onImportHandle
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "cloudUploadOutline",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.btn.import')) + " ")], 1)]), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "warning",
      "size": "sm",
      "disabled": !_vm.companyId
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateInvoiceWithTicketHandle(_vm.checkedItems);
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "increase",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.title')) + " ")], 1)])], 1), _c('b-table', {
    ref: "refInvoiceTicketListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.getInvoiceTickets,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "bordered": "",
      "busy": _vm.loading,
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "fields": _vm.tableColumsInvoiceTicketList
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumsInvoiceTicketList, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-nowrap"
          }, [column.key === 'checkbox' ? _c('div', [_c('b-form-checkbox', {
            attrs: {
              "name": "id-all-check-box",
              "checked": _vm.isAll
            },
            on: {
              "change": _vm.selectAll
            }
          })], 1) : _c('div', [_vm._v(_vm._s(_vm.$t("invoice.invoiceTickets.fields.".concat(data.column))))])])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          attrs: {
            "name": "item-check-box",
            "checked": _vm.isChecked(row.item.id)
          },
          on: {
            "change": function change($event) {
              return _vm.chooseItem(row.item);
            }
          }
        })];
      }
    }, {
      key: "cell(customerName)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.customerName ? _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.customerName) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])];
      }
    }, {
      key: "cell(ticketNumber)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.ticketNumber ? _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.ticketNumber) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])];
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.bookingCode ? _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.bookingCode) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])];
      }
    }, {
      key: "cell(flightTrip)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.flightTrip ? _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.flightTrip) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('BBadge', {
          staticClass: "px-75 text-nowrap",
          attrs: {
            "variant": _vm.resolveColorOfInvoiceTicketType(item.type),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.type.".concat(item.type))) + " ")])];
      }
    }, {
      key: "cell(isInvoiced)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('BBadge', {
          staticClass: "px-75 text-nowrap",
          attrs: {
            "variant": _vm.resolveIsInvoicedColor(item.isInvoiced, 'value') || 'secondary',
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.isInvoicedFilter.".concat(_vm.resolveIsInvoiced(item.isInvoiced, 'value').label))) + " ")])];
      }
    }, {
      key: "cell(flightDate)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.flightDate ? _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.flightDate).date) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).date) + " ")])];
      }
    }, {
      key: "cell(totalAmount)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.unitPrice)) + " ")])];
      }
    }, {
      key: "cell(collectionFee)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.collectionFee)) + " ")])];
      }
    }, {
      key: "cell(valueAddedTax)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.taxTypes.".concat(item.valueAddedTax))) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref12) {
        var _item$invoiceItems;
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.window.hover",
            value: _vm.$t('invoice.invoiceTickets.btn.view'),
            expression: "$t('invoice.invoiceTickets.btn.view')",
            modifiers: {
              "window": true,
              "hover": true
            }
          }],
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark",
            "to": {
              name: 'apps-invoiceTicketDetail',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "showOutline",
            "size": "18",
            "color": "black"
          }
        })], 1), item.isInvoiced && item !== null && item !== void 0 && (_item$invoiceItems = item.invoiceItems) !== null && _item$invoiceItems !== void 0 && _item$invoiceItems.length ? _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.window.hover",
            value: _vm.$t('invoice.invoiceTickets.btn.viewInvoice'),
            expression: "$t('invoice.invoiceTickets.btn.viewInvoice')",
            modifiers: {
              "window": true,
              "hover": true
            }
          }],
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.onViewInvoiceHandle(item);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "invoice",
            "size": "18",
            "color": "black"
          }
        })], 1) : _vm._e(), !item.isInvoiced ? _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.window.hover",
            value: _vm.$t('invoice.invoiceTickets.btn.edit'),
            expression: "$t('invoice.invoiceTickets.btn.edit')",
            modifiers: {
              "window": true,
              "hover": true
            }
          }],
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark",
            "to": {
              name: 'apps-invoiceTicketDetail',
              params: {
                id: item.id
              },
              query: {
                edit: true
              }
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "editOutline",
            "size": "18",
            "color": "black"
          }
        })], 1) : _vm._e(), !item.isInvoiced ? _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.window.hover",
            value: _vm.$t('invoice.create.title'),
            expression: "$t('invoice.create.title')",
            modifiers: {
              "window": true,
              "hover": true
            }
          }],
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.onCreateInvoiceWithTicketHandle([item]);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "newFileOutline",
            "size": "18",
            "color": "black"
          }
        })], 1) : _vm._e(), !item.isInvoiced ? _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.window.hover",
            value: _vm.$t('invoice.invoiceTickets.btn.delete'),
            expression: "$t('invoice.invoiceTickets.btn.delete')",
            modifiers: {
              "window": true,
              "hover": true
            }
          }],
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.onDeleteHandle(item.id);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "trash",
            "size": "18",
            "color": "black"
          }
        })], 1) : _vm._e()], 1)];
      }
    }], null, true)
  }), _c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "6",
      "md": "4",
      "order": "1"
    }
  }, [_c('v-select', {
    staticClass: "d-inline-block mx-50",
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "options": _vm.sizePerPageOptions,
      "clearable": false,
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " / " + _vm._s(_vm.$t('page')) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " / " + _vm._s(_vm.$t('page')) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex-center",
    attrs: {
      "cols": "12",
      "md": "4",
      "order": "3",
      "order-md": "2"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalInvoiceTickets,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex-center justify-content-end",
    attrs: {
      "cols": "6",
      "md": "4",
      "order": "2",
      "order-md": "3"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])])], 1), _c('InvoiceTicketsImportModal', {
    on: {
      "fetch-data": _vm.refetchData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }