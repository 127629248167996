<template>
  <validation-observer
    ref="refFormObserver"
    class="w-100"
  >
    <div
      v-if="isFileSuccess || isImporting"
      class="box d-flex-center bg-light flex-column py-2 px-3"
    >
      <!-- ANCHOR Top table -->
      <div class="d-flex-between w-100 mb-75">
        <h4>
          {{ $t('importValidateHeader_1') }} <em class="text-info">{{ fileName }}</em> {{ $t('importValidateHeader_2') }}
        </h4>
        <div>
          <b-button
            variant="outline-warning"
            @click="$emit('reset')"
          >
            {{ $t('uploadAgain') }}
          </b-button>
        </div>
      </div>
      <IAmOverlay :loading="isImporting">
        <!-- SECTION Table data import -->
        <b-table
          sticky-header
          class="position-relative"
          style="max-height: 60vh"
          responsive
          show-empty
          :empty-text="$t('noRecordFund')"
          :items="tableData"
          :fields="tableFields"
          no-border-collapse
        >
          <template
            v-for="column in tableFields"
            #[`head(${column.key})`]
          >
            <div
              :key="column.label"
              class="d-flex-center gap-1"
            >
              <span v-if="column.label !== 'actions'">{{ $t(`invoice.invoiceTickets.fields.${column.label}`) }}</span>
              <small
                v-if="column?.required"
                class="text-danger fw-700"
              >(*)</small>
            </div>
          </template>

          <!-- ANCHOR no -->
          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>

          <!-- ANCHOR flightDate -->
          <template #cell(flightDate)="data">
            <validation-provider
              #default="validationContext"
              immediate
              :name="$t('invoice.invoiceTickets.fields.flightDate')"
              mode="eager"
              rules="required"
              :vid="`flightDate-${data.index}`"
            >
              <flat-pickr
                :id="`flightDate-${data.index}`"
                v-model="data.item.flightDate"
                class="d-none"
                :config="{
                    ...configFlatPickr,
                }"
              />
              <div
                :id="`flightDate-${data.index}-show`"
                class="form-control p-0"
                :class="validationContext.errors[0] ? 'is-invalid' : ''"
              >
                <flat-pickr
                  v-model="data.item.flightDate"
                  :name="$t('invoice.invoiceTickets.fields.flightDate')"
                  class="form-control px-50 py-0"
                  :config="{
                    ...configFlatPickr,
                    altInputClass: 'form-control input px-1 py-0 border-transparent height-25rem',
                  }"
                  :placeholder="$t('invoice.invoiceTickets.fields.flightDate')"
                />
              </div>
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`flightDate-${data.index}-show`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR bookingCode -->
          <template #cell(bookingCode)="data">
            <validation-provider
              #default="validationContext"
              immediate
              :name="$t('invoice.invoiceTickets.fields.bookingCode')"
              mode="eager"
              rules=""
              :vid="`bookingCode-${data.index}`"
            >
              <b-form-input
                :id="`bookingCode-${data.index}`"
                v-model="data.item.bookingCode"
                :placeholder="$t('invoice.invoiceTickets.fields.bookingCode')"
                :state="validationContext.errors[0] ? false : null"
                :formatter="onlyUpperCaseFormatter"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`bookingCode-${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR ticketNumber -->
          <template #cell(ticketNumber)="data">
            <validation-provider
              #default="validationContext"
              immediate
              :name="$t('invoice.invoiceTickets.fields.ticketNumber')"
              mode="eager"
              rules="required"
              :vid="`ticketNumber-${data.index}`"
            >
              <b-form-input
                :id="`ticketNumber-${data.index}`"
                v-model="data.item.ticketNumber"
                :placeholder="$t('invoice.invoiceTickets.fields.ticketNumber')"
                :state="validationContext.errors[0] ? false : null"
                :formatter="onlyUpperCaseFormatter"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`ticketNumber-${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR customerName -->
          <template #cell(customerName)="data">
            <validation-provider
              #default="validationContext"
              immediate
              :name="$t('invoice.invoiceTickets.fields.customerName')"
              mode="eager"
              rules=""
              :vid="`customerName-${data.index}`"
            >
              <b-form-input
                :id="`customerName-${data.index}`"
                v-model="data.item.customerName"
                :placeholder="$t('invoice.invoiceTickets.fields.customerName')"
                :state="validationContext.errors[0] ? false : null"
                :formatter="onlyUpperCaseFormatter"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`customerName-${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR flightTrip -->
          <template #cell(flightTrip)="data">
            <validation-provider
              #default="validationContext"
              immediate
              :name="$t('invoice.invoiceTickets.fields.flightTrip')"
              mode="eager"
              rules=""
              :vid="`flightTrip-${data.index}`"
            >
              <b-form-input
                :id="`flightTrip-${data.index}`"
                v-model="data.item.flightTrip"
                :placeholder="$t('invoice.invoiceTickets.fields.flightTrip')"
                :state="validationContext.errors[0] ? false : null"
                :formatter="onlyUpperCaseFormatter"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`flightTrip-${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR type -->
          <template #cell(type)="data">
            <validation-provider
              #default="validationContext"
              immediate
              :name="$t('invoice.invoiceTickets.fields.type')"
              mode="eager"
              rules="required"
              :vid="`type-${data.index}`"
            >
              <!-- <b-form-input
                  :id="`type-${data.index}`"
                  v-model="data.item.type"
                  :placeholder="$t('invoice.invoiceTickets.fields.type')"
                  :state="validationContext.errors[0] ? false : null"
                  :formatter="onlyUpperCaseFormatter"
                /> -->
              <div :id="`type-${data.index}-show`">
                <b-form-input
                  :id="`type-${data.index}`"
                  :value="data.item.type"
                  class="d-none"
                />
                <v-select
                  v-model="data.item.type"
                  append-to-body
                  :calculate-position="withPopper"
                  :name="$t('invoice.invoiceTickets.fields.invoiceTicketType')"
                  :class="`w-100 ${validationContext.errors[0] ? 'is-invalid' : ''}`"
                  style="font-size: 1rem; width: 150px !important;"
                  :options="INVOICE_TICKET_TYPE_OPTIONS"
                  label="label"
                  :clearable="false"
                  :reduce="val => val.value"
                  :placeholder="$t('placeholderSelect')"
                >
                  <template #option="{ label }">
                    <BBadge
                      :variant="resolveColorOfInvoiceTicketType(label) || 'secondary'"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.invoiceTickets.type.${label}`) }}
                    </BBadge>
                  </template>

                  <template #selected-option="{ label }">
                    <BBadge
                      :variant="resolveColorOfInvoiceTicketType(label) || 'secondary'"
                      pill
                      class="px-75"
                    >
                      {{ $t(`invoice.invoiceTickets.type.${label}`) }}
                    </BBadge>
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </div>
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`type-${data.index}-show`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR totalAmount -->
          <template #cell(totalAmount)="data">
            <validation-provider
              #default="validationContext"
              immediate
              :name="$t('invoice.invoiceTickets.fields.totalAmount')"
              mode="eager"
              rules="required"
              :vid="`totalAmount-${data.index}`"
            >
              <b-form-input
                v-model="data.item.unitPrice"
                :placeholder="$t('invoice.invoiceTickets.fields.totalAmount')"
                :state="validationContext.errors[0] ? false : null"
                :formatter="onlyUpperCaseFormatter"
                class="d-none"
              />
              <IAmInputMoney
                :id="`totalAmount-${data.index}`"
                :value-money.sync="data.item.unitPrice"
                class="flex-grow-1"
                :state="validationContext.errors[0] ? false : null"
                :name="$t('invoice.invoiceTickets.fields.totalAmount')"
                :can-input-negative="true"
                :placeholder="$t('invoice.invoiceTickets.fields.totalAmount')"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`totalAmount-${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR valueAddedTax -->
          <template #cell(valueAddedTax)="data">
            <validation-provider
              #default="validationContext"
              immediate
              :name="$t('invoice.invoiceTickets.fields.valueAddedTax')"
              mode="eager"
              rules="required"
              :vid="`valueAddedTax-${data.index}`"
            >
              <div :id="`valueAddedTax-${data.index}-show`">
                <b-form-input
                  :id="`valueAddedTax-${data.index}`"
                  :value="data.item.valueAddedTax"
                  class="d-none"
                />
                <v-select
                  v-model="data.item.valueAddedTax"
                  :name="$t('invoice.invoiceTickets.fields.valueAddedTax')"
                  :class="`w-100 ${validationContext.errors[0] ? 'is-invalid' : ''}`"
                  style="font-size: 1rem; width: 100px !important;"
                  :options="TAX_TYPES"
                  append-to-body
                  :calculate-position="withPopper"
                  label="label"
                  :clearable="false"
                  :reduce="val => val.label"
                  :state="validationContext.errors[0] ? false : null"
                  :placeholder="$t('placeholderSelect')"
                >
                  <template #option="{ label }">
                    {{ $t(`invoice.taxTypes.${label}`) }}
                  </template>
                  <template #selected-option="{ label }">
                    {{ $t(`invoice.taxTypes.${label}`) }}
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </div>
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`valueAddedTax-${data.index}-show`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
              <b-tooltip
                v-else-if="data.item.valueAddedTax"
                :target="`valueAddedTax-${data.index}-show`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="dark"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ $t(`invoice.taxTypes.${data.item.valueAddedTax}`) }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR collectionFee -->
          <template #cell(collectionFee)="data">
            <validation-provider
              #default="validationContext"
              immediate
              :name="$t('invoice.invoiceTickets.fields.collectionFee')"
              mode="eager"
              rules="required"
              :vid="`collectionFee-${data.index}`"
            >
              <b-form-input
                v-model="data.item.collectionFee"
                :placeholder="$t('invoice.invoiceTickets.fields.collectionFee')"
                class="d-none"
                :state="validationContext.errors[0] ? false : null"
                :formatter="onlyUpperCaseFormatter"
              />
              <IAmInputMoney
                :id="`collectionFee-${data.index}`"
                :value-money.sync="data.item.collectionFee"
                class="flex-grow-1"
                :state="validationContext.errors[0] ? false : null"
                :name="$t('invoice.invoiceTickets.fields.collectionFee')"
                :can-input-negative="true"
                :placeholder="$t('invoice.invoiceTickets.fields.collectionFee')"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`collectionFee-${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR collectionFee -->
          <template #cell(actions)="data">
            <BButton
              variant="flat-danger"
              class="p-50 rounded-circle"
              @click="removeRow(data.index)"
            >
              <IAmIcon
                icon="trash"
                size="18"
              />
            </BButton>
          </template>
        </b-table>
        <!-- !SECTION -->
      </IAmOverlay>
    </div>

    <!-- ANCHOR BUTTON ACTION -->
    <div class="w-full m-50 d-flex-center gap-2">
      <b-button
        variant="flat-danger"
        class="p-1"
        @click.prevent="$emit('hide-modal')"
      >
        {{ $t('cancel') }}
      </b-button>
      <b-button
        class="p-1"
        variant="primary"
        :disabled="isImportFailed || !tableData?.length"
        @click.prevent="$emit('import-file', tableData, refFormObserver)"
      >
        {{ $t('import') }}
      </b-button>
    </div>
  </validation-observer>
</template>

<script>
import {
  BButton, BTable, BFormInput, BTooltip,
  BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toRefs, computed, ref } from '@vue/composition-api'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { createPopper } from '@popperjs/core'

import { typeOfEmployeeOptionsFilterToString, genderOptionsToString } from '@/constants/selectOptions'
import IAmOverlay from '@/components/IAmOverlay.vue'
import { onlyUpperCaseFormatter, trimInput } from '@/@core/comp-functions/forms/formatter-input'
import VueI18n from '@/libs/i18n'
import { TAX_TYPES } from '@/constants/invoice'

import formValidation from '@core/comp-functions/forms/form-validation'

import {
  required, min, email, isUnique, isBelongTo, duplicateImport, userName,
} from '@validations'

import useInvoiceTicketHandle, { INVOICE_TICKET_TYPE_OPTIONS, resolveColorOfInvoiceTicketType } from '../useInvoiceTicketHandle'

export default {
  components: {
    BButton,
    BTable,
    BFormInput,
    BTooltip,
    vSelect: () => import('vue-select'),
    IAmOverlay,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    flatPickr: () => import('vue-flatpickr-component'),
  },
  props: {
    isFileSuccess: {
      type: Boolean,
      default: false,
    },
    isImportFailed: {
      type: Boolean,
      default: false,
    },
    isImporting: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      default: '',
    },
    dataImport: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { tableColumsInvoiceTicketList } = useInvoiceTicketHandle()

    const tableFields = computed(() => [{ label: 'no', key: 'no' }].concat(tableColumsInvoiceTicketList.value
      .filter(i => !['checkbox', 'isInvoiced', 'createdAt'].includes(i.label))))

    const { refFormObserver, getValidationState, resetForm } = formValidation()

    const tableData = toRefs(props).dataImport
    const locale = computed(() => VueI18n.locale).value
    const configFlatPickr = ref({
      dateFormat: 'Y-m-d',
      locale: locale === 'vi' ? Vietnamese : null,
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y',
      shorthandCurrentMonth: true,
      disableMobile: true,
    })

    function removeRow(index) {
      tableData.value.splice(index, 1)
    }
    return {
      tableFields,
      tableData,
      refFormObserver,
      getValidationState,
      resetForm,

      typeOfEmployeeOptionsFilterToString,
      genderOptionsToString,

      required,
      min,
      email,
      isUnique,
      isBelongTo,
      userName,
      duplicateImport,
      trimInput,
      configFlatPickr,
      onlyUpperCaseFormatter,
      INVOICE_TICKET_TYPE_OPTIONS,
      resolveColorOfInvoiceTicketType,
      TAX_TYPES,
      removeRow,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '200px'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
}

</script>

<style lang="scss" scoped>
.box {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  padding: 30px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  border-radius: 4px;
  background: #161d31;
  height: 100%;
}

.is-invalid ::v-deep {
  .vs__dropdown-toggle{
    border-color: red;
  }
}
</style>
